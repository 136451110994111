<template>
  <div
    class="container mx-auto bg-primary max-w-full min-h-screen flex flex-col items-center justify-center prose"
  >
    <h1 class="antialiased text-secondary text-center text-3xl md:text-4xl">Let's Onboard!</h1>
    <div>
      <label
        class="input input-bordered flex items-center gap-3 mb-3 text-secondary border-secondary"
      >
        <IconUser />
        <input
          type="text"
          class="grow text-secondary placeholder-neutral"
          placeholder="username"
          v-model="username"
          aria-label="Username"
        />
      </label>
      <label
        class="input input-bordered flex items-center gap-3 mb-3 text-secondary border-secondary"
      >
        <IconEmail />
        <input
          type="text"
          class="grow text-secondary placeholder-neutral"
          placeholder="email"
          v-model="email"
          aria-label="Email"
        />
      </label>
      <label class="input input-bordered flex items-center gap-3 text-secondary border-secondary">
        <IconKey />
        <input
          :type="isPasswordVisible ? 'text' : 'password'"
          class="grow text-secondary placeholder-neutral"
          placeholder="password"
          v-model="password"
        />
        <button type="button" class="right-3 text-secondary" @click="togglePasswordVisibility">
          <IconEye v-if="isPasswordVisible" />
          <IconEyeOff v-else />
        </button>
      </label>
    </div>
    <div class="mt-3">
      <LoadingRing :loading="authStore.loadingData" />
      <button
        v-if="!authStore.loadingData"
        class="btn md:btn-md btn-outline btn-secondary mt-2"
        :disabled="!canRegister"
        @click="registerUser"
        aria-label="Register"
      >
        Register
      </button>
    </div>
    <div class="mt-3">
      <h4 class="antialiased text-neutral text-center text-sm">
        Already Registered? <a @click="redirectToLogin" class="text-secondary">Login</a>
      </h4>
    </div>
  </div>
</template>

<style scoped>
.input {
  background-color: transparent;
}

button {
  border: none;
  cursor: pointer;
}

button:focus {
  outline: none;
}

a {
  cursor: pointer;
}

a:hover {
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.8);
}
</style>

<script setup>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth'

import IconUser from '@/components/icons/IconUser.vue'
import IconEmail from '@/components/icons/IconEmail.vue'
import IconKey from '@/components/icons/IconKey.vue'
import IconEye from '@/components/icons/IconEye.vue'
import IconEyeOff from '@/components/icons/IconEyeOff.vue'
import LoadingRing from '@/components/LoadingRing.vue'

const username = ref('')
const email = ref('')
const password = ref('')
const isPasswordVisible = ref(false)

const router = useRouter()
const authStore = useAuthStore()

const canRegister = computed(() => {
  return !!email.value.trim() && !!username.value.trim() && !!password.value.trim()
})

const togglePasswordVisibility = () => {
  isPasswordVisible.value = !isPasswordVisible.value
}

const redirectToLogin = () => {
  router.push({
    name: 'login'
  })
}

const registerUser = async () => {
  try {
    await authStore.register(username.value, email.value, password.value)

    if (authStore.isAuthenticated) {
      router.push({
        name: 'onboard'
      })
    }
  } catch (e) {
    console.error(e)
  }
}
</script>
