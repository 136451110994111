import { defineStore } from 'pinia'
import axios from 'axios'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    loadingData: false,
    userData: JSON.parse(localStorage.getItem('user_data')) || null,
    token: localStorage.getItem('auth_token') || null
  }),
  getters: {
    isAuthenticated: (state) => {
      return !!state.token
    }
  },
  actions: {
    logout() {
      this.token = null
      this.userData = null

      localStorage.removeItem('auth_token')
      localStorage.removeItem('user_data')
    },
    setUserData(data) {
      this.token = data.jwt
      this.userData = data.user

      localStorage.setItem('auth_token', this.token)
      localStorage.setItem('user_data', JSON.stringify(this.userData))
    },
    loadCachedUserData() {
      const storedUserData = localStorage.getItem('user_data')
      if (storedUserData) {
        this.userData = JSON.parse(storedUserData)
      }

      const storedToken = localStorage.getItem('auth_token')
      if (storedToken) {
        this.token = storedToken
      }
    },
    async login(identifier, password) {
      try {
        this.loadingData = true

        const response = await axios.post(`${import.meta.env.VITE_APP_API_URL}/auth/local`, {
          identifier,
          password
        })

        console.log(response.data)
        this.setUserData(response.data)
      } catch (error) {
        console.error('Login failed:', error.response?.data?.message || error.message)
        throw error // Re-throw for handling in the calling component
      } finally {
        this.loadingData = false
      }
    },
    async register(username, email, password) {
      try {
        this.loadingData = true

        const response = await axios.post(
          `${import.meta.env.VITE_APP_API_URL}/auth/local/register`,
          {
            username,
            email,
            password
          }
        )
        console.log(response.data)
        this.setUserData(response.data)
      } catch (error) {
        console.error('Login failed:', error.response?.data?.message || error.message)
        throw error // Re-throw for handling in the calling component
      } finally {
        this.loadingData = false
      }
    }
  }
})
