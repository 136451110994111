<template>
  <div
    class="container mx-auto bg-primary max-w-full min-h-screen flex flex-col items-center justify-center prose"
  >
    <h1 class="antialiased text-secondary text-center text-2xl md:text-3xl">
      Hi{{ authStore.userData ? ` ${authStore.userData?.username}` : '' }}, who are you?
    </h1>
    <div class="mt-4 lg:mt-2">
      <div class="join join-vertical lg:join-horizontal flex lg:items-center">
        <button
          class="btn btn-outline btn-secondary join-item btn-lg"
          @click="redirectToArtistProfile"
        >
          Artist
        </button>
        <button
          class="btn btn-outline btn-secondary join-item btn-lg"
          @click="redirectToVendorProfile"
        >
          Vendor
        </button>
        <button
          class="btn btn-outline btn-secondary join-item btn-lg"
          @click="redirectToVenueProfile"
        >
          Venue
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
button {
  cursor: pointer;
}

button:focus {
  outline: none;
}
</style>

<script setup>
// import { useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth'

// const router = useRouter()
const authStore = useAuthStore()

const redirectToArtistProfile = () => {
  // router.push({
  //   name: 'registration'
  // })
}

const redirectToVendorProfile = () => {
  // router.push({
  //   name: 'registration'
  // })
}

const redirectToVenueProfile = () => {
  // router.push({
  //   name: 'registration'
  // })
}
</script>
