import './assets/app.css'

import { createApp } from 'vue'

import { createPinia } from 'pinia'
import axios from 'axios'
import VueAxios from 'vue-axios'
import * as Sentry from '@sentry/vue'

import App from './App.vue'
import router from './router'

const app = createApp(App)

Sentry.init({
  app,
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({
      router,
      tracingOrigins: ['localhost', 'https://ashor.live'],
      shouldCreateSpanForRequest(url) {
        // Disable tracing for API calls
        return !url.includes('cms.ashor.live')
      }
    }),
    Sentry.replayIntegration()
  ],
  // Tracing
  tracesSampleRate: 1.0, // You can reduce this to control tracing frequency, e.g., 0.5
  // Session Replay
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  // Track components
  trackComponents: true,
  hooks: ['activate', 'create', 'unmount', 'mount', 'update']
})

const pinia = createPinia()
app.use(pinia)

app.use(router)

app.use(VueAxios, axios)
app.provide('axios', app.config.globalProperties.axios)

app.mount('#app')
