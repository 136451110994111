import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/auth'

import TicketRegView from '@/views/TicketRegView.vue'
import TicketPreview from '@/views/TicketPreview.vue'
import TicketValidationView from '@/views/TicketValidationView.vue'
import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import RegistrationView from '@/views/RegistrationView.vue'
import OnboardingView from '@/views/OnboardingView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      // redirect: '/flood-aid/tickets/'
      name: 'home',
      component: HomeView
    },
    {
      path: '/onboard',
      name: 'onboard',
      component: OnboardingView,
      meta: { requiresAuth: true }
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView
    },
    {
      path: '/registration',
      name: 'registration',
      component: RegistrationView
    },
    {
      path: '/flood-aid/tickets/',
      name: 'flood-aid-registration',
      component: TicketRegView
    },
    {
      path: '/flood-aid/tickets/:id/preview',
      name: 'flood-aid-ticket-preview',
      component: TicketPreview
    },
    {
      path: '/flood-aid/tickets/:id/validate',
      name: 'flood-aid-ticket-validation',
      component: TicketValidationView,
      meta: { requiresAuth: true }
    }
  ]
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore()

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Check if the route requires authentication
    if (!authStore.isAuthenticated) {
      next({
        name: 'login',
        query: {
          redirect: to.fullPath
        }
      })
    }
  }
  next()
})

export default router
